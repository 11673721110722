<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <v-icon :left="true">
        {{ $vuetify.icons.values.alert }}
      </v-icon>
      <h6 class="text-h6">
        {{ $trans("remove_category") }}
      </h6>
    </v-card-title>
    <v-card-text v-if="services === 0">
      <p class="text-subtitle-1">
        {{ $trans("remove_category_warning") }}
      </p>
      <p class="accent_light--text text-subtitle-1">
        {{ $trans("no_way_to_undo_action") }}
      </p>
    </v-card-text>
    <v-card-text v-else>
      <p class="text-subtitle-1">
        {{
          $trans("services_already_assigned_in_category", {
            services: services,
          })
        }}
      </p>
    </v-card-text>

    <v-card-actions v-if="services === 0">
      <v-spacer />
      <v-btn :disabled="isLoading" color="light" text @click="$emit('close')">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn
        :disabled="isLoading"
        color="accent_light"
        text
        @click="removeCategory(getConfirmDialog.data)"
      >
        {{ $trans("remove") }}
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-spacer />
      <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn color="primary" text @click="$emit('close')"> OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import categoryServiceActions from "@/calendesk/mixins/categoryServiceActions";

export default {
  name: "RemoveCategoryDialog",
  mixins: [categoryServiceActions],
  computed: {
    services() {
      return this.getConfirmDialog.data.services;
    },
  },
};
</script>
